(function($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    $.libInit(".part_ui_link, .part_ui_btn", "lib_ripple", function(selector) {
        $(selector).nl_lib_ripple();
    });

    $.libInit(".lib--rellax", "lib_rellax", function(selector) {
        if ($(selector).length && win.width() > 960) {
            $.importScript(cdnjs.rellax, function() {
                bodyLoaded(function(){
                    new Rellax(selector, {
                        center: true
                    });
                });
            });
        }
    });

    $.libInit(".lib--webgl-portrait", "lib_webgl_portrait", function(selector) {
        $(selector).nl_lib_webgl_portrait();
    });

    (function fn_pictureFill() {
        let picture = document.createElement('picture'),
            strObj = '' + picture;

        if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
        {
            $.importScript(cdnjs.picturefill);
        }
    })();

    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.importScript(cdnjs.cookieconsent);
    }
})(jQuery);