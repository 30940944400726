(function comp(name) {
    const selector = $(`.${name}`);

    if (selector.length) {
        selector.on("click", "[data-scroll-next]", function(){
            $('html, body').animate({
                scrollTop: selector.next().offset().top - 63
            }, 1000);
        });

        window.lui[name] = comp;
    }
})("comp_base_visual");